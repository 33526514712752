<template>
  <div
    class="container"
    v-title
    id="newContract"
    :data-title="$t('i18n.newContract')"
  >
    <div id="outer-title">{{ $t("i18n.newContract") }}</div>
    <div class="half-form">
      <jl-form
        :columns="columns"
        @onSubmit="onSubmit"
        @resetForm="resetForm"
      ></jl-form>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import jlForm from "../../components/form";
export default {
  name: "UpkeepContrctViewadd",
  components: {
    jlForm,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const router = useRouter();
    let upkeepType = "10";
    const state = reactive({
      columns: [
        // 需要展示的列
        {
          prop: "upkeepType",
          label: "contractType",
          type: "select",
          props: { label: "code", value: "value" },
          data: [],
          uploadUrl: "upkeep_type",
          value: "10",
          view: "10, 20, 30, 40",
          change: (val) => {
            upkeepType = val;
            state.columns.map((item) => {
              item.show = item.view.indexOf(val) > -1 ? true : false;
            });
          },
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "number",
          label: "maintenanceContractNo",
          view: "10",
          show: upkeepType == "10",
          type: "input",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "property",
          label: "contractNature",
          type: "select",
          props: { label: "code", value: "value" },
          data: [],
          uploadUrl: "property",
          show: upkeepType == "10",
          view: "10",
        },
        {
          prop: "status",
          label: "contractStatus",
          type: "select",
          props: { label: "code", value: "value" },
          data: [],
          uploadUrl: "contractStatus",
          show: upkeepType == "10",
          view: "10",
        },
        {
          prop: "source",
          label: "contractSource",
          type: "select",
          props: { label: "code", value: "value" },
          data: [],
          uploadUrl: "source",
          show: upkeepType == "10",
          view: "10",
        },
        {
          prop: "ownerFullname",
          label: "ownerName",
          type: "input",
          show: upkeepType == "10",
          view: "10",
        },
        {
          prop: "ownerShortname",
          label: "ownerNickname",
          type: "input",
          show: upkeepType == "10",
          view: "10",
        },
        {
          prop: "firstPartyName",
          label: "partyA",
          type: "input",
          show: upkeepType == "10",
          view: "10",
        },
        {
          prop: "secondPartyName",
          label: "partyB",
          type: "input",
          show: upkeepType == "10",
          view: "10",
        },
        {
          prop: "beginDate",
          label: "effectiveDate",
          type: "date",
          show: upkeepType == "10",
          view: "10",
          change: (val) => {
            setValueByRank(val, "beginDate");
          },
        },
        {
          prop: "endDate",
          label: "expiryDate",
          type: "date",
          show: upkeepType == "10",
          view: "10",
          change: (val) => {
            setValueByRank(val, "endDate");
          },
        },
        {
          prop: "durationMonth",
          label: "contractDuration",
          type: "input",
          show: upkeepType == "10",
          view: "10",
          readonly: true,
        },
        {
          prop: "signDate",
          label: "signingDate",
          type: "date",
          show: upkeepType == "10",
          view: "10",
        },
        {
          prop: "value",
          label: "contractAmount",
          type: "input",
          show: upkeepType == "10" || upkeepType == "20" || upkeepType == "30",
          view: "10, 20, 30",
        },
        {
          prop: "areaAttribute",
          label: "regionAttribute",
          type: "select",
          props: { label: "name", value: "id" },
          data: [],
          view: "10, 20, 30, 40",
        },
        {
          prop: "remindPhone",
          label: "smsNumber",
          type: "input",
          show: upkeepType == "10",
          rules: [{ required: true, message: t("i18n.input") }],
          view: "10",
        },
        {
          prop: "purchaseNo",
          label: "purchaseNo",
          type: "input",
          show: upkeepType == "10",
          view: "10",
        },
        {
          prop: "maintainerName",
          label: "maintenancePerson",
          type: "select",
          props: { label: "name", value: "id" },
          data: [],
          show: upkeepType == "10",
          view: "10",
        },
        {
          prop: "maintainerPhone",
          label: "contractInformation",
          type: "input",
          show: upkeepType == "10" || upkeepType == "40",
          view: "10, 40",
        },
        {
          prop: "isSubpackage",
          label: "subcontracting",
          type: "switch",
          show: upkeepType == "10",
          text: { aText: "yes", aVal: "1", iText: "no", iVal: "0" },
          view: "10",
        },
        {
          prop: "subpackageCompanyName",
          label: "subcontractCompany",
          type: "input",
          show: upkeepType == "10",
          view: "10",
        },
        {
          prop: "manuscript",
          label: "originalcontract",
          type: "upload",
          action: "contracts",
          limit: "1",
          value: [],
          view: "10, 20, 30, 40",
          tip: "只能上传jpg/png/pdf文件",
        },
        {
          prop: "quotationNo",
          label: "quotationNo",
          type: "input",
          show: parseInt(upkeepType) > 10,
          view: "20, 30, 40",
        },
        {
          prop: "number",
          label: "equipmentContractNo",
          type: "input",
          show: parseInt(upkeepType) > 10,
          view: "20, 30, 40",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "stockNo",
          label: "materialNo",
          type: "input",
          show: upkeepType == "20",
          view: "20",
        },
        {
          prop: "materialName",
          label: "materialName",
          type: "input",
          show: upkeepType == "20",
          view: "20",
        },
        {
          prop: "specification",
          label: "spec",
          type: "input",
          show: upkeepType == "20",
          view: "20",
        },
        {
          prop: "unit",
          label: "units",
          type: "input",
          show: upkeepType == "20",
          view: "20",
        },
        {
          prop: "counts",
          label: "num",
          type: "input",
          show: upkeepType == "20",
          view: "20",
        },
        {
          prop: "quotationPrice",
          label: "quotationPrice",
          type: "input",
          show: upkeepType == "20",
          view: "20",
        },
        {
          prop: "quotationTotal",
          label: "totalPrice",
          type: "input",
          show: upkeepType == "20",
          view: "20",
        },
        {
          prop: "customerName",
          label: "clientName",
          type: "input",
          show: parseInt(upkeepType) > 10,
          view: "20, 30, 40",
        },
        {
          prop: "partsNo",
          label: "accessoriesNumber",
          type: "input",
          show: upkeepType == "20",
          view: "20",
        },
        {
          prop: "consignee",
          label: "consignee",
          type: "input",
          show: upkeepType == "20",
          view: "20",
        },
        {
          prop: "contactPhone",
          label: "consigneeNo",
          type: "input",
          show: upkeepType == "20" || upkeepType == "30",
          view: "20, 30",
        },
        {
          prop: "contactAddress",
          label: "deliveryAddress",
          type: "input",
          show: upkeepType == "20" || upkeepType == "30",
          view: "20, 30",
        },
        {
          prop: "paymentDate",
          label: "receivedDate",
          type: "date",
          show: parseInt(upkeepType) > 10,
          view: "20, 30, 40",
        },
        {
          prop: "sellNo",
          label: "salesOrderNo",
          type: "input",
          show: upkeepType == "20" || upkeepType == "30",
          view: "20, 30",
        },
        {
          prop: "deliveryNo",
          label: "deliveryOrderNo",
          type: "input",
          show: upkeepType == "20" || upkeepType == "30",
          view: "20, 30",
        },
        {
          prop: "reformContent",
          label: "renewalContent",
          type: "input",
          show: upkeepType == "30",
          view: "30",
        },
        {
          prop: "materialPrice",
          label: "materialQuotation",
          type: "input",
          show: upkeepType == "30",
          view: "30",
        },
        {
          prop: "artificialPrice",
          label: "manualQuotation",
          type: "input",
          show: upkeepType == "30",
          view: "30",
        },
        {
          prop: "totalPrice",
          label: "totalAmt",
          type: "input",
          show: upkeepType == "30" || upkeepType == "40",
          view: "30, 40",
        },
        {
          prop: "quotation",
          label: "quotation",
          type: "input",
          show: upkeepType == "40",
          view: "40",
        },
        {
          prop: "consignee",
          label: "onSiteContactPerson",
          type: "input",
          show: upkeepType == "40",
          view: "40",
        },
        {
          prop: "supportContent",
          label: "supportedTech",
          type: "input",
          show: upkeepType == "40",
          view: "40",
        },
      ],
    });

    const onSubmit = async (row) => {
      if (upkeepType == "10") {
        await proxy.$api.maintenance.isExistContract({
          number: row.number,
        });
        addContract(row);
      } else {
        addContract(row);
      }
    };

    const addContract = async (data) => {
      for (let key in data) {
        if (data[key] instanceof Date) {
          data[key] = proxy.$defined.format(data[key]);
        } else if (Array.isArray(data.manuscript)) {
          let urls = [];
          data.manuscript.map((item) => {
            urls.push(item.url);
          });
          data.manuscript = urls.join(",");
        }
      }
      await proxy.$api.maintenance.addContract(data);
      proxy.$defined.tip(t("i18n.new") + t("i18n.success"), "success");
      router.go(-1);
    };

    const resetForm = () => {
      router.go(-1);
    };

    const getMainPerson = async () => {
      // 维保人员
      let { data } = await proxy.$api.maintenance.getMainPerson();
      state.columns.map((item) => {
        if (item.prop === "maintainerName") {
          item.data = data;
        }
      });
    };

    const init = () => {
      getMainPerson();
      areaAttribute();
    };

    const areaAttribute = async () => {
      // 区域属性
      let { data } = await proxy.$api.maintenance.areaAttribute();
      state.columns.map((item) => {
        if (item.prop === "areaAttribute") {
          item.data = data;
        }
      });
    };

    const setValueByRank = (val, key) => {
      // 计算合同时间段
      state[key] = val;
      if (state.beginDate && state.endDate) {
        let sTime = Date.parse(new Date(state.beginDate));
        let eTime = Date.parse(new Date(state.endDate));
        let days = Math.floor((eTime - sTime) / (24 * 3600 * 1000));
        state.columns.map((item) => {
          if (item.prop === "durationMonth") {
            item.value = parseInt(days / 30);
          } else if (item.prop === "duration") {
            item.value = parseInt(days / 365);
          } else if (item.prop === key) {
            item.value = val;
          }
        });
      }
    };

    init();

    return {
      ...toRefs(state),
      onSubmit,
      setValueByRank,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
#newContract {
  .form-button,
  .form-button .el-form-item__content {
    width: 100%;
    margin: 0 !important;
    text-align: center;
  }
}
</style>
